/**
 * Created by mateimisarca on 18/09/2020
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { updateUi } from 'actions/uiActions';

import UserContainer from 'containers/UserContainer/UserContainer';
import makeSelectUiState from 'selectors/uiSelectors';

import Menu from 'components/Menu';

import logo from 'assets/images/Logo-Bloclit-v1.png';
import logo_ideapapel from 'assets/images/logo-ideea-papel.png';

import { UI_IS_NAVIGATION_OPEN } from '../../constants';

import './TopMenu.scss';
import { isMobileOnly } from 'react-device-detect';

const mapStateToProps = state => ({
  ui: makeSelectUiState(state),
});

export default @connect(mapStateToProps)
class Header extends Component {
  constructor() {
    super();

    this.state = {
      isMenuOpen: false,
    };
  }

  render() {
    const {
      isLoggedIn,
      ui: { constants },
    } = this.props;

    return (
      <header className="TopMenu">
        <div className="header-top" style={ { backgroundColor: constants.colors.topBar } }>
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-sm-8">
                <div className="header-left">
                  <ul className="TopMenu-topList">
                    <li><i className="fa fa-phone" />
                      <a href={ `tel:${ constants.phoneLink }` }>{ constants.phone }</a>
                    </li>
                    { constants.phone2 && (
                      <li><i className="fa fa-phone" />
                        <a href={ `tel:${ constants.phoneLink2 }` }>{ constants.phone2 }</a>
                      </li>
                    ) }
                    <li><i className="fa fa-envelope-o" />
                      <a href={ `mailto:${ constants.mail }` } target="_top">{ constants.mail }</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/*<div className="col-md-5 col-sm-4">
                <div className="header-right-div">
                  <div className="soical-profile">
                    <span className="social-title">Urmareste-ne:</span>
                    <ul>
                      <li><a href="https://www.facebook.com/clubulprofesorilorromania" target="_blank"><i
                        className="fa fa-facebook" /></a></li>

                    </ul>
                  </div>
                </div>
              </div>*/ }
            </div>
          </div>
        </div>
        <div className="hd-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-12 col-xs-8">
                <div className="logo TopMenu-logo">
                  { isMobileOnly && (
                    <span className="TopMenu-hamburger"
                          onClick={ () => this.setState({ isMenuOpen: !this.state.isMenuOpen }) }
                    >
                      <i className="fa-solid fa-bars" />
                    </span>
                  ) }
                  <Link to="/">
                    <img style={ { height: '65px', maxWidth: 'none' } } src={ constants.logo } alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-md-7 col-sm-9 menu-center">
                <div className="menu">
                  { isMobileOnly ? this.state.isMenuOpen && <Menu /> : <Menu /> }
                  {/*<div className="search-bar-icon">*/ }
                  {/*  <div className="site-search">*/ }
                  {/*    <span data-toggle="dropdown"><i className="fa fa-search"></i></span>*/ }
                  {/*    <div className="search-forum dropdown-menu animation slideUpIn">*/ }
                  {/*      <form action="#">*/ }
                  {/*        <input placeholder="Search For Site" type="text">*/ }
                  {/*          /!*<input type="submit" value="Go"/>*!/*/ }
                  {/*        </input>*/ }
                  {/*      </form>*/ }
                  {/*    </div>*/ }
                  {/*  </div>*/ }
                  {/*</div>*/ }
                </div>
              </div>
              <div className="col-md-3 col-sm-3 applay-button-area">
                { constants.logIn && (
                  <div className="applay-button">
                    { isLoggedIn ? (
                      <Link to="/profile">Profilul meu</Link>
                    ) : (
                      <Link to="/auth/login">Log In</Link>
                    ) }
                  </div>
                ) }
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
